import { render, staticRenderFns } from "./default.vue?vue&type=template&id=373f2f49&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=373f2f49&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "373f2f49",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RPHHeader: require('/usr/src/app/src/components/v2/templates/RPHHeader/index.vue').default,RPHHeaderMobile: require('/usr/src/app/src/components/v2/templates/RPHHeaderMobile/index.vue').default,RPHCityModal: require('/usr/src/app/src/components/v2/organisms/RPHCityModal/RPHCityModal.vue').default,RPHModalContainer: require('/usr/src/app/src/components/v2/templates/RPHModalContainer/index.vue').default,SigninModal: require('/usr/src/app/src/components/organisms/signin/SigninModal.vue').default,RPHTabBar: require('/usr/src/app/src/components/v2/molecules/RPHTabBar/index.vue').default,RPHFooter: require('/usr/src/app/src/components/v2/templates/RPHFooter/index.vue').default,RPHToastContainer: require('/usr/src/app/src/components/v2/organisms/RPHToastContainer/index.vue').default})
